html {
  background-color: #ffffff;
}
.alert-pop-over {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
textarea {
  height: 60vh;
}

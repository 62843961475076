.landing-nav-container .nav-icon-color {
  color: #3382a7 !important;
}
.landing-nav-container .nav-brand-text {
  color: #2b6d8b !important;
  font-family: Pacifico;
  font-size: 2em;
}
.landing-nav-container .mini-collpase-nav-container {
  background-color: #dce6e6;
  border-radius: 5px;
}
.button-theme-color {
  background-color: #2b6d8b;
}
.button-theme-color:hover {
  background-color: #3382a7;
}
.button-theme-color:active {
  background-color: #1e5a78 !important;
}
.landing-hero-container .text-color-highlight {
  color: #2b6d8b !important;
}
.landing-hero-container .landing-hero-image {
  max-height: 300px;
}
.landing-hero-container .hero-main-text-container {
  display: flex;
  justify-content: center;
}
.landing-hero-container .hero-main-text-container .hero-main-text {
  max-width: 800px;
  color: #032a46;
}
.features-main-container .feature-icon-background {
  background-color: #2b6d8b;
}

html {
  background-color: #dce6e6;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
html ::-webkit-scrollbar {
  width: 10px;
}
html ::-webkit-scrollbar-track {
  background: #3f3f3f;
}
html ::-webkit-scrollbar-thumb {
  background: #888;
}
html ::-webkit-scrollbar-thumb:hover {
  background: #6e1f1f;
}
.mainContainer {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background: #ffffff;
}
.mainContainer .header-container {
  background: linear-gradient(180deg, #2b6d8b 1%, #1a516a 40%, #032a46 100%);
}
.mainContainer .header-container .header-container-first-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.mainContainer .header-container .logo-font-header {
  font-family: Pacifico;
  font-size: 1em;
}
.mainContainer .header-container .header-container-info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: start;
}
.mainContainer .sticky-top-custom {
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 1020;
}
.mainContainer .addAndSearchBar-container {
  background-color: #032a46;
}
.mainContainer .addAndSearchBar-container .add-new-record-btn {
  background-color: #2b6d8b;
}
.mainContainer .addAndSearchBar-container .add-new-record-btn:hover {
  background-color: #3382a7;
}
.mainContainer .addAndSearchBar-container .add-new-record-btn:active {
  background-color: #1e5a78;
}
.mainContainer .setupAccountModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent black background */
  display: flex;
  /* flexbox to center the modal dialog */
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.mainContainer .additem-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* semi-transparent black background */
  display: flex;
  /* flexbox to center the modal dialog */
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.mainContainer .application-list-container {
  height: max-content;
  margin-top: 30px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(230, 230, 230, 0.692);
}
@media screen and (max-width: 1200px) {
  .mainContainer .application-list-container {
    border-radius: 0;
  }
}
.mainContainer .checkbox {
  margin: 0 10px;
}
.mainContainer .item-applied {
  background-color: #a4a4a4 !important;
}
.mainContainer .item-container {
  height: max-content;
  margin: 15px 5px;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.mainContainer .item-container .item-hover-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  width: auto;
}
.mainContainer .item-container .item-hover-container .item-start-block {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.mainContainer .item-container .item-hover-container .item-hover-toolbar-edit-button {
  color: #00121c;
}
.mainContainer .item-container .item-hover-container .item-hover-toolbar-edit-button :hover {
  cursor: pointer;
}
.mainContainer .item-container .item-hover-container .item-dragDrop :hover {
  cursor: grab;
}
.mainContainer .item-container .item-hover-container .item-dragDrop :active {
  cursor: grabbing;
}
.mainContainer .item-container .item-hover-container .job-name {
  padding: 0 10px;
}
.mainContainer .item-container .item-hover-container .link-container {
  padding: 0 10px;
  overflow-wrap: anywhere;
}
.mainContainer .item-end-column {
  display: flex;
  width: auto;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
}
.mainContainer .item-end-column .item-delete-button {
  color: #930505;
}
.mainContainer .item-end-column .item-delete-button :hover {
  cursor: pointer;
}
.mainContainer .item-end-column .date-box {
  padding: 0px;
}
.truncate-link {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.truncate-link:hover {
  overflow: visible;
  white-space: normal;
  max-width: fit-content;
}
.viewRecordModal .textarea-container {
  display: flex;
  flex-direction: column;
}
.viewRecordModal .description-textarea {
  flex: 7;
  /* 70% of the height */
  height: 50vh;
  /* You can adjust this value based on your layout needs */
  overflow: auto;
  /* To make sure content doesn't overflow the container */
}
.viewRecordModal .notes-textarea {
  flex: 3;
  /* 30% of the height */
  overflow: auto;
}
